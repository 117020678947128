import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { MarketSearchService } from '../../services/market-search.service';
import { SharedService } from '../../services/shared.service';
import { User } from '../../types/user';

import { AnalyticsService } from 'src/app/dashboard/services/analytics.service';
import * as searchQueries from '../../../../assets/search-queries.json';
import { FilterQueryService } from '../../services/filter-query.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  showDiv: boolean = true;
  public recentActivityList: any = [];
  public marketSearch: any = "";
  public user: User | null;
  public initials: any = "";

  searchOptions = searchQueries;
  searchSuggestions: string[] = new Array<string>;
  //public applySearchFilter: boolean = true;
  jobCategories = [];
  states = [] as any;
  companies = [];
  industries = [];


  constructor(private router: Router, public marketSearchService: MarketSearchService,
    public sharedService: SharedService, private messageService: MessageService, private authService: AuthService,
    private render: Renderer2, private filterQueryService: FilterQueryService, private analyticsService: AnalyticsService) {
    // Listen to the router events and filter for NavigationEnd events.
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      // Check the URL path and determine if the div should be shown or not.
      this.showDiv = event.url !== '/d/market-search';
    });
    //this.searchOptions = searchQueries.map(item => {return item;});
  }
  ngOnInit() {
    //let user:any = localStorage.getItem("li");
    this.user = this.authService.currentUser;
    this.createInitials();
    this.analyticsService.getStates().subscribe({
      next: (res) => {
        for (let i = 0; i < res.data.states.length; i++) {
          this.states.push({ label: this.sharedService.getStateFullName(res.data.states[i].state), state: res.data.states[i].state })
        }
      }
    });
    this.analyticsService.getJobs(null).subscribe({
      next: (res) => {
        this.jobCategories = res.data.jobCategories;
      }
    });
    this.analyticsService.getIndustries().subscribe({
      next: (res) => {
        this.industries = res.data.industries;
      }
    });
    /*this.analyticsService.getComps().subscribe(res => {
      console.log(JSON.stringify(res));
    }, error => {
      console.log(JSON.stringify(error));
    });*/
  }

  ngAfterViewInit(): void {
    // needed to make NgAutoComplete control, component scss and html style is not working
    const searchDropbox = document.getElementsByClassName("p-inputtext").item(0) as HTMLElement;
    if (searchDropbox) {
      searchDropbox.style.padding = '0.15rem';
      searchDropbox.style.border = '0px';
      searchDropbox.style.width = '100%';
      searchDropbox.style.background = '#00000000';
    };
    const searchDropwrapper = document.getElementsByClassName("p-inputwrapper").item(1) as HTMLElement;
    if (searchDropwrapper) {
      searchDropwrapper.style.width = '100%';
    };
  }


  getRecentActivities() {
    let userId = "1"; //change when auth guard added
    this.marketSearchService.getRecentActivities(this.user?._id).subscribe(results => {
      this.recentActivityList = results;
      this.sharedService.updateRecentActivities(this.recentActivityList);

    })
    // this.recentActivitiesUpdated.emit(this.recentActivityList);

  }

  onSearchChange(event: any) {
    console.log(event.query);
    const query: string = (event.query as string).toLowerCase();
    this.searchSuggestions = this.searchOptions.data.filter(function (str) {
      return str.includes(query);
    });
    /*if(this.applySearchFilter && (event.target.value as string).length > 3) {
      this.applySearchFilter = false;
      const countdown = interval(1000).subscribe((interval) => {
        countdown.unsubscribe();
        this.applySearchFilter = true;
      });
    }*/
  }

  onSearch() {
    this.marketSearchService.searchMarket(this.marketSearch, this.user?._id).subscribe((results: any) => {
      if (results.aggregation.length > 0) {
        this.getRecentActivities();
        this.router.navigate(['/d/forecasting'], {
          queryParams: {
            query: JSON.stringify(results?.aggregation),
            search: this.marketSearch,
            soc6Code: results?.soc6Code,
            jobCategory: results?.jobCategory,
            state: JSON.stringify(results?.dataMemberValue),
            isHourlySalary: results?.isHourlySalary,
            isCompany: results?.isCompany,
            companies: results?.companies,
            allResData: JSON.stringify(results)
          },
          // queryParamsHandling: 'merge'
        });
      }
      else {
        this.showWarn();
      }

    });
  }

  public showAdvanceSearch: boolean = false;
  toggleAdvanceSearch() {
    if (this.showAdvanceSearch) this.showAdvanceSearch = false;
    else this.showAdvanceSearch = true;
  }
  closeAdvanceSearch() {
    this.searchJobCategories = "";
    this.searchStates = "";
    this.searchCompanies = "";
    this.searchIndustries = "";
    this.searchType = "";;
    this.searchSort = "";;
    this.showAdvanceSearch = false;
  }

  searchType: string;
  searchJobCategories: string;
  searchStates: string;
  searchCompanies: string;
  searchIndustries: string;
  searchSort: string;
  advanceSearch() {
    this.filterQueryService.filterQuery(this.searchType, this.searchJobCategories, this.searchStates, this.searchCompanies, this.searchIndustries, this.searchSort).subscribe((res: any) => {
      // let forecastingQuery = encodeURIComponent(JSON.stringify(res));
      // document.location.assign('/d/forecasting?query=' + forecastingQuery);

      this.router.navigate(['/d/forecasting'], {
        queryParams: {
          query: JSON.stringify(res?.aggregation),
          search: this.marketSearch,
          soc6Code: res?.soc6Code,
          jobCategory: res?.jobCategory,
          state: JSON.stringify(res?.dataMemberValue),
          isHourlySalary: res?.isHourlySalary,
          isCompany: res?.isCompany,
          companies: res?.companies,
          allResData: JSON.stringify(res)
        },
        // queryParamsHandling: 'merge'
      });
      this.closeAdvanceSearch();

    }, error => {
      this.messageService.add({ severity: 'error', summary: 'Error fetching the query', detail: error });
    });
  }

  showWarn() {
    this.messageService.add({ severity: 'warn', key: 'gtr', detail: 'Please refine the query and search again' });
  }
  logout() {
    localStorage.clear();
    const callbackUrl = encodeURIComponent(location.origin + '/auth/callback');
    document.location.assign(environment.authUrl + '/auth/logout');
  }
  resetPassword() {

  }
  createInitials() {
    if (this.user?.firstName && this.user?.lastName) {
      this.initials = this.user.firstName[0] + this.user.lastName[0];
      this.initials = this.initials.toUpperCase();
    }
    else if (this.user?.firstName && !this.user?.lastName) {
      this.initials = this.user.firstName[0];
      this.initials = this.initials.toUpperCase();
    }
    else if (!this.user?.firstName && this.user?.lastName) {
      this.initials = this.user.lastName[0];
      this.initials = this.initials.toUpperCase();
    }
    else {
      this.initials = "TE";
    }
  }
}
