import { ChangeDetectorRef, Component } from '@angular/core';
import { NgZone } from '@angular/core';
import { MarketSearchService } from '../../services/market-search.service';
import { SharedService } from '../../services/shared.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from '../../types/user';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  public suggestionsList: any = [];
  public recentActivityList: any = [];
  public marketSearch: any = "";
  public result: any = "";
  lastActivity = {} as any;
  public user: User | null;
  currentPage: number = 1;

  constructor(private marketSearchService: MarketSearchService,
    public sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private router: Router,
    private authService: AuthService) {
  }

  ngOnInit() {
    //let user: any = localStorage.getItem("li");
    this.user = this.authService.currentUser;
    this.getRecentActivities();
    this.sharedService.recentActivities$.subscribe(activities => {
      this.getRecentActivities();
      // this.recentActivityList = activities;
    });

  }

  getRecentActivities() {
    this.marketSearchService.getRecentActivities(this.user?._id).subscribe(results => {
      console.log("results = ", results)
      this.recentActivityList = results;
      this.lastActivity = { ...(this.recentActivityList?.[0]?.['queries']?.[0] || {}) };
      if (this.lastActivity?.result) {
        this.lastActivity.allResData = this.lastActivity?.result;
        this.lastActivity.result = JSON.stringify(JSON.parse(this.lastActivity?.result)?.aggregation);
      }
      if (this.lastActivity?.result) {
        this.lastActivity.dataMemberValue = JSON.stringify(JSON.parse(this.lastActivity?.result)?.dataMemberValue);
      }
    });
  }

  archiveActivity(id: string) {
    const userId = 1; // Replace this with dynamic data from auth guard or similar
    this.marketSearchService.archiveActivity(userId, id).subscribe({
      next: (results) => {
        if (!results) {
          console.log("No results returned from archiveActivity, ID =", id);
        } else {
          console.log("Archive activity successful, response:", results);
        }
        this.getRecentActivities(); // Refresh the list of activities if needed
      },
      error: (error) => {
        console.error("Error archiving activity, ID =", id, "Error:", error);
      }
    });
  }

  onActivityClick(query) {
    console.log(JSON.parse(query.result));

    this.router.navigate(['/d/forecasting'], {
      queryParams: {
        query: JSON.stringify(JSON.parse(query?.result)?.aggregation),
        state: JSON.stringify(JSON.parse(query?.result)?.dataMemberValue),
        search: query?.query,
        soc6Code: query?.soc6Code,
        jobCategory: query?.jobCategory,
        allResData: query?.result
      }
    });
  }

  openRequisitionsPage() {
    window.open(environment.intervuAiRequisitionsURL, "_blank");
  }

  openInterviewsPage() {
    window.open(environment.intervuAiInterviewsURL, "_blank");
  }

  loadMoreActivities() {
    this.currentPage++;
    this.marketSearchService.getRecentActivities(this.user?._id,this.currentPage).subscribe(results => {
      console.log("results = ", results)
      this.recentActivityList = results;
      this.lastActivity = { ...(this.recentActivityList?.[0]?.['queries']?.[0] || {}) };
      if (this.lastActivity?.result) {
        this.lastActivity.allResData = this.lastActivity?.result;
        this.lastActivity.result = JSON.stringify(JSON.parse(this.lastActivity?.result)?.aggregation);
      }
      if (this.lastActivity?.result) {
        this.lastActivity.dataMemberValue = JSON.stringify(JSON.parse(this.lastActivity?.result)?.dataMemberValue);
      }
    });
  }

}
