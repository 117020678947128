import { NgModule } from '@angular/core';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { GraphComponent } from './components/graph/graph.component';
import { HookResizeDirective } from './directives/resize.directive';
import { RouterModule } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgbDatepickerModule, NgbDropdownModule, NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CapitalizeFirstPipe } from './pipes/capitalize-first.pipe';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AutoCompleteModule } from 'primeng/autocomplete';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    GraphComponent,
    HookResizeDirective,
    CapitalizeFirstPipe
  ],
  imports: [
    CommonModule,
    HighchartsChartModule,
    RouterModule,
    FormsModule,
    MultiSelectModule,
    NgbDatepickerModule,
    ToastModule,
    AutoCompleteModule,
    InputSwitchModule,
    NgbTooltipModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    GraphComponent,
    HookResizeDirective,
    FormsModule,
    HighchartsChartModule,
    ToastModule,
    MultiSelectModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbPaginationModule,
    CapitalizeFirstPipe,
    InputSwitchModule,
    NgbTooltipModule
  ],
  providers: []

})
export class SharedModule { }
