import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilterQueryService {
  apiUrl = environment.queryEngineUrl;

  constructor(private http: HttpClient) { }

  filterQuery(type, jobCategory, state, company, industry, sort) {
    // if (company) {
    //   let companyArr = company.split(',');
    //   if (companyArr.length > 1) {
    //     company = companyArr.map(s => s.trim());
    //   }
    // }

    const payload = {
      "type": type,
      "jobCategory": jobCategory,
      "state": state,
      "company": company,
      "industry": industry,
      "sort": sort
    }
    const response = this.http.post(this.apiUrl+"/recent-activities/filterQuery", payload);
    return response;
  }
}
