import { Component, inject } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiResponse } from 'src/app/shared/types/api-response';
import { TokenResponse } from 'src/app/shared/types/token-response';

@Component({
  selector: 'app-callback',
  template: `<div class="login-wrapper"><p>Authenticating, please wait!</p></div>`
})
export class CallbackComponent {
  constructor(private _authService: AuthService, private _activatedRoute: ActivatedRoute, private _router: Router, private http: HttpClient) {
    const authCode = _activatedRoute.snapshot.queryParamMap.get('auth_code');
    const redirectUrl = _activatedRoute.snapshot.queryParamMap.get('redirect_url');
    if (!authCode) _router.navigate(['/auth/login'], { queryParams: { 'redirect_url': redirectUrl } });
    /*const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    headers.set('X-API-KEY', '23541243515345345');*/
    const authTokenUrl = environment.authUrl + '/auth/tokens/' + authCode;
    this.http.get(authTokenUrl).subscribe(response => {
      console.log('Callback Response: ' + JSON.stringify(response));
      const tokenResponse = new ApiResponse(response).data as unknown as TokenResponse;
      const jwthelper = new JwtHelperService();
      const expired = jwthelper.isTokenExpired(tokenResponse.token);
      if(expired) {
        console.error('The authentication token has expired');
        _router.navigate(['/auth/login'], { queryParams: { 'redirect_url': redirectUrl } });
      }
      console.log('User Authenticated: ' + JSON.stringify(jwthelper.decodeToken(tokenResponse.token)));
      _authService.token = tokenResponse.token;
      console.log("redirectUrl = ",redirectUrl)
      if(redirectUrl) {
        _router.navigate(["d/market-search"]);
      }
      else _router.navigate(['']);
    }, error => {
      console.error('Callback token error: ' + JSON.stringify(error));
      _router.navigate(['/auth/login'], { queryParams: { 'redirect_url': redirectUrl } });
    });
  }
}
