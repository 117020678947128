<header>
  <div class="container-fluid">
    <div class="row">
      <div class="col branding"><img src="assets/logo/Login-Logo.svg" alt="TalentEdgeAI" /></div>
      <div class="col global-search" *ngIf="showDiv">
        <div class="position-relative">
          <span class="ic-prepend icon-search"></span>
          <!--<input type="text" class="form-control form-control-lg" placeholder="Search" [(ngModel)]="marketSearch"
            (keyup.enter)="onSearch()" (input)="onSearchChange($event)">-->
          <p-autoComplete [disabled]="showAdvanceSearch" class="form-control form-control-lg" [(ngModel)]="marketSearch"
            [suggestions]="searchSuggestions" (completeMethod)="onSearchChange($event)" (keyup.enter)="onSearch()" />
          <span class="ic-append icon-filter" (click)="toggleAdvanceSearch()"></span>
          <div class="advance_filters" style="display: block;" *ngIf="showAdvanceSearch">
            <div class="form-group">
              <div class="d-flex flex-row">
                <div class="custom-control custom-radio mr-4">
                  <input type="radio" class="custom-control-input" id="searchTypeDemand" name="searchType" value="Demand" [(ngModel)]="searchType">
                  <label class="custom-control-label" for="searchTypeDemand">Demand</label>
                </div>
                
                <div class="custom-control custom-radio mr-4">
                  <input type="radio" class="custom-control-input" id="searchTypeSalary" name="searchType" value="Salary" [(ngModel)]="searchType">
                  <label class="custom-control-label" for="searchTypeSalary">Salary</label>
                </div>
                <div class="custom-control custom-radio mr-4">
                  <input type="radio" class="custom-control-input" id="searchTypeHourlyPay" name="searchType" value="Hourly Pay" [(ngModel)]="searchType">
                  <label class="custom-control-label" for="searchTypeHourlyPay">Hourly Pay</label>
                </div>
                <div class="custom-control custom-radio">
                  <input type="radio" class="custom-control-input" id="searchTypeSupply" name="searchType" value="Supply" [(ngModel)]="searchType" disabled>
                  <label class="custom-control-label" for="searchTypeSupply">Supply</label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <p-multiSelect placeholder="Job Categories: Data Scientist, Data Analyst" [style]="{'width':'100%'}" [virtualScroll]="true" [virtualScrollItemSize]="30" [options]="jobCategories" [(ngModel)]="searchJobCategories"  optionLabel="jobCategory" optionValue="jobCategory"></p-multiSelect>
              <!--<input name="searchJobCategory" [(ngModel)]="searchJobCategory" type="text" class="form-control" placeholder="Data Scientist, Data Analyst">-->
            </div>
            <div class="form-group">
              <p-multiSelect placeholder="States: California, Illinois" [style]="{'width':'100%'}" [virtualScroll]="true" [virtualScrollItemSize]="30" [options]="states" [(ngModel)]="searchStates" optionLabel="label" optionValue="state"></p-multiSelect>
              <!--<input name="searchState" [(ngModel)]="searchState" type="text" class="form-control" placeholder="California, Illinois">-->
            </div>
            <div class="form-group">
              <!--<p-multiSelect placeholder="Companies: Apple, Microsoft" [style]="{'width':'100%'}" [virtualScroll]="true" [virtualScrollItemSize]="30" [options]="companies" [(ngModel)]="searchCompanies"></p-multiSelect>-->
              <input name="searchCompany" [(ngModel)]="searchCompanies" type="text" class="form-control" placeholder="Companies: Apple, Microsoft">
            </div>
            <div class="form-group">
              <p-multiSelect placeholder="Industries: Information Technology, Healthcare" [style]="{'width':'100%'}" [virtualScroll]="true" [virtualScrollItemSize]="30" [options]="industries" [(ngModel)]="searchIndustries" optionLabel="industry" optionValue="industry"></p-multiSelect>
              <!--<input name="searchIndustry" [(ngModel)]="searchIndustry" type="text" class="form-control" placeholder="Information Technology, Healthcare">-->
            </div>
            <div class="form-group">
              <div class="d-flex flex-row align-items-center">
                <div class="custom-control custom-radio mr-4">
                  <input type="radio" class="custom-control-input" id="searchSortLow" name="searchSort" value="1" [(ngModel)]="searchSort">
                  <label class="custom-control-label" for="searchSortLow">Low</label>
                </div>
                <div class="custom-control custom-radio mr-4">
                  <input type="radio" class="custom-control-input" id="searchSortHigh" name="searchSort" value="-1" [(ngModel)]="searchSort">
                  <label class="custom-control-label" for="searchSortHigh">High</label>
                </div>
                <div style="margin-left: auto;">
                  <button class="btn btn-outline-primary mr-4" (click)="closeAdvanceSearch()">Cancel</button>
                  <button class="btn btn-primary" (click)="advanceSearch()">Advance Search</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col user-profile">
        <div class="d-flex flex-row-reverse align-items-center">
          <div class="pl-4">
            <!-- Dropdown Trigger -->
            <!-- <img src="assets/images/avatar-img.png" class="userpic dropdown-toggle" id="dropdownMenuLink"
              data-toggle="dropdown" aria-haspopup="true" alt="User"> -->

            <img src="https://via.placeholder.com/150/FCFCFC/102e42/?text={{initials}}" class="userpic dropdown-toggle"
              id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" alt="User">


            <!-- Dropdown Menu -->
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
              <span class="dropdown-item-text howdy">Hi, {{user?.firstName}} {{user?.lastName}}</span>
              <span class="dropdown-item-text howdy" style="font-size: x-small;">{{user?.role?.toUpperCase()}}</span>
              <span class="dropdown-item-text howdy"
                style="font-size: x-small;">{{user?.accountName?.toUpperCase()}}</span>
              <div class="dropdown-divider"></div>
              <!--<a class="dropdown-item btn btn-link btn-sm" (click)="resetPassword()">Reset Password</a>-->
              <a class="dropdown-item btn btn-link btn-sm" (click)="logout()">Logout</a>
            </div>
          </div>
          <div class="notification position-relative">
            <span class="icon-bell-ringing"></span>
            <!-- Notification Bubble based on count -->
            <!-- <div class="notification-bubble"></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
