import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MarketSearchService {

  constructor(private http: HttpClient) { }
  apiUrl = environment.queryEngineUrl;

  searchSuggestions() {
    return this.http.get(`${this.apiUrl}/recent-activities/searchSuggestions`, {});

  }
  searchMarket(prompt,userId) {
    return this.http.post(`${this.apiUrl}/recent-activities/v8/marketSearch`, { prompt,userId });

  }
  getRecentActivities(userId,page=1) {
    return this.http.get(`${this.apiUrl}/recent-activities?userId=${userId}&page=${page}`, {});

  }
  archiveActivity(userId, id) {
    return this.http.post(`${this.apiUrl}/recent-activities/archive`, { userId, id });

  }
}
